.App {
  font-family: "Lato";
  background-color: #171717;
  min-height: 120vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.thirty {
  pointer-events: none;
}

.content {
  margin-top: 4px;
}

.shimmer {
  font-weight: 300;
  font-size: 3em;
  display: inline;
  margin-left: -2px;
  overflow: hidden;
}

.shimmer {
  text-align: center;
  color: rgba(186, 138, 40, 0.5);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#171717),
    to(#171717),
    color-stop(0.5, #fff)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#171717),
    to(#171717),
    color-stop(0.5, #fff)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#171717),
    to(#171717),
    color-stop(0.5, #fff)
  );
  -webkit-background-size: 40px 100%;
  -moz-background-size: 40px 100%;
  background-size: 40px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 4s;
  -moz-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #171717;
}

@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

.Typewriter {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  top: 6px;
}


/* COUNTDOWN TIMER */
.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: #ba8a28;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 25px;
  height: 25px;
}

.countdown-item span {
  color: #ba8a28;
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
}

.pyro {
  overflow: hidden !important;
}

.pyro > .before, .pyro > .after {
  position: absolute;
  overflow: hidden;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: -36px -217.6666666667px #BA8A28, -25px -211.6666666667px #EFB652, 178px -300.6666666667px #B6AC63, 101px 56.3333333333px #6B9A83, -50px -32.6666666667px #47384E, 161px -304.6666666667px #6D8768, -197px -149.6666666667px #35585A, -102px -295.6666666667px #DCC15A, 131px -356.6666666667px #e1ff00, 47px -375.6666666667px #00ffee, 150px -130.6666666667px #ff5e00, 82px 66.3333333333px #EFB652, -218px -412.6666666667px #7bff00, 86px -374.6666666667px #e1ff00, 164px -192.6666666667px #88ff00, -146px -414.6666666667px #00ff80, 230px -367.6666666667px #00ff80, 228px 62.3333333333px #b3ff00, 6px -76.6666666667px #1aff00, -77px 49.3333333333px #0dff00, -216px -272.6666666667px #ff5e00, 235px -261.6666666667px #a2ff00, 120px -144.6666666667px #e1ff00, 106px -128.6666666667px #9900ff, 236px -145.6666666667px #ea00ff, -123px -336.6666666667px #00ff1e, 107px -24.6666666667px #35585A, -233px -278.6666666667px #c8ff00, -235px -281.6666666667px #001aff, -100px -406.6666666667px #00a2ff, 105px 61.3333333333px #f2ff00, -186px -341.6666666667px #0077ff, -201px -76.6666666667px yellow, 128px -349.6666666667px #8400ff, 125px 19.3333333333px #ff2600, 24px -187.6666666667px #d000ff, 126px -394.6666666667px #fffb00, 78px -406.6666666667px #00ffb3, -204px -144.6666666667px #00ff73, 181px -307.6666666667px #ff005e, 188px -415.6666666667px #7bff00, 29px -90.6666666667px #ff00d5, 142px -67.6666666667px #00ff2b, 67px -216.6666666667px #00ffee, 39px -228.6666666667px #ff00ee, -63px 82.3333333333px #BA8A29, 156px -168.6666666667px #BA8A38, -43px -49.6666666667px #BA8A28, -40px -4.6666666667px #00e6ff, -156px -290.6666666667px #0d00ff, 140px 22.3333333333px #ff0900;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: -36px -217.6666666667px #BA8A28, -25px -211.6666666667px #EFB652, 178px -300.6666666667px #B6AC63, 101px 56.3333333333px #6B9A83, -50px -32.6666666667px #47384E, 161px -304.6666666667px #6D8768, -197px -149.6666666667px #35585A, -102px -295.6666666667px #DCC15A, 131px -356.6666666667px #e1ff00, 47px -375.6666666667px #00ffee, 150px -130.6666666667px #ff5e00, 82px 66.3333333333px #EFB652, -218px -412.6666666667px #7bff00, 86px -374.6666666667px #e1ff00, 164px -192.6666666667px #88ff00, -146px -414.6666666667px #00ff80, 230px -367.6666666667px #00ff80, 228px 62.3333333333px #b3ff00, 6px -76.6666666667px #1aff00, -77px 49.3333333333px #0dff00, -216px -272.6666666667px #ff5e00, 235px -261.6666666667px #a2ff00, 120px -144.6666666667px #e1ff00, 106px -128.6666666667px #9900ff, 236px -145.6666666667px #ea00ff, -123px -336.6666666667px #00ff1e, 107px -24.6666666667px #35585A, -233px -278.6666666667px #c8ff00, -235px -281.6666666667px #001aff, -100px -406.6666666667px #00a2ff, 105px 61.3333333333px #f2ff00, -186px -341.6666666667px #0077ff, -201px -76.6666666667px yellow, 128px -349.6666666667px #8400ff, 125px 19.3333333333px #ff2600, 24px -187.6666666667px #d000ff, 126px -394.6666666667px #fffb00, 78px -406.6666666667px #00ffb3, -204px -144.6666666667px #00ff73, 181px -307.6666666667px #ff005e, 188px -415.6666666667px #7bff00, 29px -90.6666666667px #ff00d5, 142px -67.6666666667px #00ff2b, 67px -216.6666666667px #00ffee, 39px -228.6666666667px #ff00ee, -63px 82.3333333333px #BA8A29, 156px -168.6666666667px #BA8A38, -43px -49.6666666667px #BA8A28, -40px -4.6666666667px #00e6ff, -156px -290.6666666667px #0d00ff, 140px 22.3333333333px #ff0900;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: -36px -217.6666666667px #BA8A28, -25px -211.6666666667px #EFB652, 178px -300.6666666667px #B6AC63, 101px 56.3333333333px #6B9A83, -50px -32.6666666667px #47384E, 161px -304.6666666667px #6D8768, -197px -149.6666666667px #35585A, -102px -295.6666666667px #DCC15A, 131px -356.6666666667px #e1ff00, 47px -375.6666666667px #00ffee, 150px -130.6666666667px #ff5e00, 82px 66.3333333333px #EFB652, -218px -412.6666666667px #7bff00, 86px -374.6666666667px #e1ff00, 164px -192.6666666667px #88ff00, -146px -414.6666666667px #00ff80, 230px -367.6666666667px #00ff80, 228px 62.3333333333px #b3ff00, 6px -76.6666666667px #1aff00, -77px 49.3333333333px #0dff00, -216px -272.6666666667px #ff5e00, 235px -261.6666666667px #a2ff00, 120px -144.6666666667px #e1ff00, 106px -128.6666666667px #9900ff, 236px -145.6666666667px #ea00ff, -123px -336.6666666667px #00ff1e, 107px -24.6666666667px #35585A, -233px -278.6666666667px #c8ff00, -235px -281.6666666667px #001aff, -100px -406.6666666667px #00a2ff, 105px 61.3333333333px #f2ff00, -186px -341.6666666667px #0077ff, -201px -76.6666666667px yellow, 128px -349.6666666667px #8400ff, 125px 19.3333333333px #ff2600, 24px -187.6666666667px #d000ff, 126px -394.6666666667px #fffb00, 78px -406.6666666667px #00ffb3, -204px -144.6666666667px #00ff73, 181px -307.6666666667px #ff005e, 188px -415.6666666667px #7bff00, 29px -90.6666666667px #ff00d5, 142px -67.6666666667px #00ff2b, 67px -216.6666666667px #00ffee, 39px -228.6666666667px #ff00ee, -63px 82.3333333333px #BA8A29, 156px -168.6666666667px #BA8A38, -43px -49.6666666667px #BA8A28, -40px -4.6666666667px #00e6ff, -156px -290.6666666667px #0d00ff, 140px 22.3333333333px #ff0900;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: -36px -217.6666666667px #BA8A28, -25px -211.6666666667px #EFB652, 178px -300.6666666667px #B6AC63, 101px 56.3333333333px #6B9A83, -50px -32.6666666667px #47384E, 161px -304.6666666667px #6D8768, -197px -149.6666666667px #35585A, -102px -295.6666666667px #DCC15A, 131px -356.6666666667px #e1ff00, 47px -375.6666666667px #00ffee, 150px -130.6666666667px #ff5e00, 82px 66.3333333333px #EFB652, -218px -412.6666666667px #7bff00, 86px -374.6666666667px #e1ff00, 164px -192.6666666667px #88ff00, -146px -414.6666666667px #00ff80, 230px -367.6666666667px #00ff80, 228px 62.3333333333px #b3ff00, 6px -76.6666666667px #1aff00, -77px 49.3333333333px #0dff00, -216px -272.6666666667px #ff5e00, 235px -261.6666666667px #a2ff00, 120px -144.6666666667px #e1ff00, 106px -128.6666666667px #9900ff, 236px -145.6666666667px #ea00ff, -123px -336.6666666667px #00ff1e, 107px -24.6666666667px #35585A, -233px -278.6666666667px #c8ff00, -235px -281.6666666667px #001aff, -100px -406.6666666667px #00a2ff, 105px 61.3333333333px #f2ff00, -186px -341.6666666667px #0077ff, -201px -76.6666666667px yellow, 128px -349.6666666667px #8400ff, 125px 19.3333333333px #ff2600, 24px -187.6666666667px #d000ff, 126px -394.6666666667px #fffb00, 78px -406.6666666667px #00ffb3, -204px -144.6666666667px #00ff73, 181px -307.6666666667px #ff005e, 188px -415.6666666667px #7bff00, 29px -90.6666666667px #ff00d5, 142px -67.6666666667px #00ff2b, 67px -216.6666666667px #00ffee, 39px -228.6666666667px #ff00ee, -63px 82.3333333333px #BA8A29, 156px -168.6666666667px #BA8A38, -43px -49.6666666667px #BA8A28, -40px -4.6666666667px #00e6ff, -156px -290.6666666667px #0d00ff, 140px 22.3333333333px #ff0900;
  }
}
@keyframes bang {
  to {
    box-shadow: -36px -217.6666666667px #BA8A28, -25px -211.6666666667px #EFB652, 178px -300.6666666667px #B6AC63, 101px 56.3333333333px #6B9A83, -50px -32.6666666667px #47384E, 161px -304.6666666667px #6D8768, -197px -149.6666666667px #35585A, -102px -295.6666666667px #DCC15A, 131px -356.6666666667px #e1ff00, 47px -375.6666666667px #00ffee, 150px -130.6666666667px #ff5e00, 82px 66.3333333333px #EFB652, -218px -412.6666666667px #7bff00, 86px -374.6666666667px #e1ff00, 164px -192.6666666667px #88ff00, -146px -414.6666666667px #00ff80, 230px -367.6666666667px #00ff80, 228px 62.3333333333px #b3ff00, 6px -76.6666666667px #1aff00, -77px 49.3333333333px #0dff00, -216px -272.6666666667px #ff5e00, 235px -261.6666666667px #a2ff00, 120px -144.6666666667px #e1ff00, 106px -128.6666666667px #9900ff, 236px -145.6666666667px #ea00ff, -123px -336.6666666667px #00ff1e, 107px -24.6666666667px #35585A, -233px -278.6666666667px #c8ff00, -235px -281.6666666667px #001aff, -100px -406.6666666667px #00a2ff, 105px 61.3333333333px #f2ff00, -186px -341.6666666667px #0077ff, -201px -76.6666666667px yellow, 128px -349.6666666667px #8400ff, 125px 19.3333333333px #ff2600, 24px -187.6666666667px #d000ff, 126px -394.6666666667px #fffb00, 78px -406.6666666667px #00ffb3, -204px -144.6666666667px #00ff73, 181px -307.6666666667px #ff005e, 188px -415.6666666667px #7bff00, 29px -90.6666666667px #ff00d5, 142px -67.6666666667px #00ff2b, 67px -216.6666666667px #00ffee, 39px -228.6666666667px #ff00ee, -63px 82.3333333333px #BA8A29, 156px -168.6666666667px #BA8A38, -43px -49.6666666667px #BA8A28, -40px -4.6666666667px #00e6ff, -156px -290.6666666667px #0d00ff, 140px 22.3333333333px #ff0900;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}